<template>
  <div>
    <page-header name="Cronograma"/>
    <div v-if="url_timeline === ''">
      <h2>Cronograma não configurada para a promoção</h2>
    </div>
    <div v-else>
      <b-card class="p-0 m-0" style="padding: 0px !important; margin: 0px!important;">
        <iframe :src="url_timeline" :height="windowHeight" width="100%" frameborder="0" allowfullscreen/>
      </b-card>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PromotionTimeLine',
  data() {
    return {
      promotion: this.currentPromotion,
      url_timeline: '',
      windowHeight: window.innerHeight - 230,
    }
  },
  created() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      this.$router.push(`/change-promotions?ref=${this.$route.path}`)
      return
    }
    this.currentPromotion = promotion
    this.url_timeline = promotion.settings.timeline_url || ''
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight - 230
    },
  },
}
</script>

<style scoped>

</style>
